import styled from '@xstyled/styled-components'

export const FooterWrapper = styled.footer`
  padding: 9 6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: mustard;
  min-height: 246px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 6;
    min-height: 0;
  }
`

export const LinksWrapper = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  font-size: 36px;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
`

export const SubscribeWrapper = styled.div`
  width: 100%;
  max-width: 510px;
  margin-right: 5;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    padding: 0 0 2 0;
    margin-right: 0;
    input {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 640px) {
    margin: 0 -12 0 -12;
    max-width: 100vw;
    width: calc(100vw - 32px);
    input {
      font-size: 13px;
    }
  }  
`
export const MobileNav = styled.nav`
  display: none;
  align-items: center;
  max-width: 100vw;
  margin-bottom: 4;
  margin-left: -6;
  margin-right: -6;
  a,
  button {
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 2;
    font-size: 6;
  }
  a:hover,
  a.active,
  button:hover {
    text-decoration: underline;
  }
  img {
    margin: 0;
  }
  @media screen and (max-width: 640px) {
    display: flex;
  }
`
export const NavButton = styled.button`
  border: none;
  padding: 0 2;
  margin: none;
  color: black;
`
