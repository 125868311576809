import gql from 'graphql-tag'
import { CheckoutResponse, CheckoutLineItemInput } from '../../types'
import { checkoutFragment } from './shared'

export interface CheckoutLineItemsRemoveInput {
  checkoutId: string
  lineItemIds: string[]
}

export type CheckoutLineItemsRemoveResponse =
  CheckoutResponse<'checkoutLineItemsRemove'>

export const CHECKOUT_LINE_ITEMS_REMOVE = gql`
  mutation CheckoutLineItemsRemove(
    $checkoutId: ID!,
    $lineItemIds: [ID!]!
    ) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkoutUserErrors {
        __typename
        code
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${checkoutFragment}
`
