import styled, { css } from '@xstyled/styled-components'
import { Color } from '@/theme/defaultTheme'

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: dialog;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    padding: 0 4;
  }
  @media screen and (max-width: 768px) {
    padding: 0 2;
  }
`
export const ModalBackground = styled.button`
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`

export interface ModalContentProps {
  backgroundColor?: Color
}

export const ModalContent = styled.div<ModalContentProps>`
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor || 'white'};
    padding: 6;
    position: relative;
    border: 1px solid black;
    max-width: 1000px;
    width: 100%;
    height: 90vh;
    overflow: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    
    .no-line-items {
      margin-top: auto;
      span {
        font-size: 5;
        text-align: center;
        display: block;
        margin-bottom: 4;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 2 4;
      h2 {
        font-size: 5;
      }
    }
  `}
`

export const ModalCloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
  font-size: 5;
  font-family: serif;
  color: black;
  @media screen and (max-width: 768px) {
    top: 16px;
    right: 16px;
    font-size: 6;
  }
`
