import { ThemeProvider } from '@xstyled/styled-components'
import type { AppProps } from 'next/app'

import { Navigation } from '@/components/Navigation/Navigation'
import { defaultTheme } from '@/theme/defaultTheme'
import { GlobalStyles } from '@/theme/globalStyles'
import { ShopifyProvider } from '@/providers/ShopifyProvider'

import '../styles/normalized.css'
import { shopifyClient } from '@/lib/client/shopify'
import { PageProps } from '@/types/shared'
import { SiteSettingsProvider } from '@/providers/SiteSettingsProvider'
import { Footer } from '@/components/Footer'
import { ModalProvider } from '@/providers/ModalProvider'
import { Modals } from '@/views/Modals/Modals'
import Head from 'next/head'
import Script from 'next/script'

export default function App({ Component, pageProps }: AppProps<PageProps>) {
  const { siteSettings } = pageProps
  // @ts-ignore
  const previewImgUrl = siteSettings?.seo?.image?.asset?.url
  if (!siteSettings) {
    throw new Error(
      'Site settings were not fetched. Did you forget to include fetchSiteSettings in getStaticProps?',
    )
  }
  return (
    <SiteSettingsProvider siteSettings={siteSettings}>
      <ShopifyProvider query={shopifyClient.query}>
        <ThemeProvider theme={defaultTheme}>
          <ModalProvider>
            <GlobalStyles />
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=2"
              />
              <title>{siteSettings?.seo?.title || 'STRONG BABY'}</title>
              <meta name="description" content={siteSettings?.seo?.description || 'Strong Baby Productions is a multimedia entertainment company born April 1, 2020 in Los Angeles, CA.'} />
              <meta name="og:title" content={siteSettings?.seo?.title || 'STRONG BABY'} />
              <meta name="og:description" content={siteSettings?.seo?.description || 'Strong Baby Productions is a multimedia entertainment company born April 1, 2020 in Los Angeles, CA.'} />
              //@ts-ignore
              <meta name="og:image" content={previewImgUrl || '/icon-512.png'} />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=2"
              />
              <link rel="shortcut icon" href="/favicon.ico" />
              <link
                rel="apple-touch-icon-precomposed"
                href="/apple-touch-icon.png" />
              <link
                rel="icon"
                href="https://developer.mozilla.org/static/img/favicon32.png" />
            </Head>
            <Script
              src="https://www.googletagmanager.com/gtag/js?id=G-EHHDKBLYBH"
              strategy="afterInteractive"
            />
            <Script id="google-analytics" strategy="afterInteractive">
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-EHHDKBLYBH');
              `}
            </Script>
              <Navigation />
              <Modals />
                <Component {...pageProps} />
              <Footer />
          </ModalProvider>
        </ThemeProvider>
      </ShopifyProvider>
    </SiteSettingsProvider>
  )
}
