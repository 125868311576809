import * as React from 'react'
import { Required } from 'utility-types'
import styled from '@xstyled/styled-components'

const BaseText = styled.spanBox``

type BaseTextProps = React.ComponentProps<typeof BaseText>

export const Span: React.FC<BaseTextProps> = (props) => {
  return <BaseText {...props} />
}

export const Text: React.FC<Required<BaseTextProps, 'as'>> = ({
  as,
  ...textProps
}) => <BaseText as={as} {...textProps} />

type HeadingProps = BaseTextProps & {
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const Heading: React.FC<HeadingProps> = ({ level, ...textProps }) => (
  <BaseText as={level} {...textProps} />
)

/**
 * Some simple wrappers with sensible defaults
 */
export const P: React.FC<BaseTextProps> = (props) => (
  <BaseText fontFamily="serif" fontSize={5} {...props} as="p" />
)

export const Label: React.FC<BaseTextProps> = (props) => (
  <BaseText
    display="block"
    fontFamily="sans"
    fontSize={6}
    {...props}
    as="label"
  />
)
