import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import SBMotionO from '../Logo/SBMotionO'
import { Squash as Hamburger } from 'hamburger-react'
import {
  Nav,
  NavTop,
  NavBottom,
  ToolsWrapper,
  CartIconWrapper,
  NavLinksWrapper,
  NavButton,
  MenuWrapperMobile,
} from './styles'
import { useModal } from '@/providers/ModalProvider'
import { useShopify } from '@/providers/ShopifyProvider'
import { unwindEdges } from '@good-idea/unwind-edges'
import { useRouter } from 'next/router'
import { Button } from '../Button'
import { useLockScroll } from '@/hooks/useLockScroll'


const { useRef, useState, useEffect } = React

interface NavigationProps {
  /* */
}

export const Navigation: React.FC<NavigationProps> = (props) => {
  const [isSticky, setSticky] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const { openContactModal, openCartModal } = useModal()
  const { checkout } = useShopify()
  const router = useRouter()
  const { lockScroll, unlockScroll } = useLockScroll()
  const currentRoute = router.pathname
  const topRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)
  const handleScroll = () => {
    if (topRef.current && bottomRef.current) {
      setSticky(topRef.current.getBoundingClientRect().bottom <= 0)
    }
  }

  const [lineItems] = checkout ? unwindEdges(checkout.lineItems) : []
  const cartCount = lineItems?.reduce((acc, item) => acc + item.quantity, 0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  useEffect(() => {
    setOpen(false)
  }, [currentRoute])

  useEffect(() => {
    isOpen ? lockScroll() : unlockScroll()
    return () => unlockScroll()
  }, [isOpen])

  return (
    <>
      <Nav
        fixed={isSticky}
        bottomHeight={bottomRef.current?.getBoundingClientRect().height}
      >
        <NavTop ref={topRef}>
          <Link href="/">
            <SBMotionO />
          </Link>
        </NavTop>
        <NavBottom fixed={isSticky} ref={bottomRef}>
          <NavLinksWrapper>
            <div className='menu-toggle'>
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
            <Link
              href="/about"
              className={currentRoute === '/about' ? 'active' : undefined}
              id="aboutLink"
            >
              About
            </Link>
            <Image
              src={'/drawings/sb.svg'}
              alt={'Cartoon drawing of a baby lifting a barbell'}
              width={60}
              height={50}
              className="nav-image"
            />
            <Link
              href="/products"
              className={currentRoute === '/products' ? 'active' : undefined}
              id="shopLink"
            >
              Shop
            </Link>
            <Image
              src={'/drawings/sun.svg'}
              alt={'Cartoon drawing of a man'}
              width={50}
              height={50}
              className="nav-image"
            />
            <Link
              href="/news"
              className={currentRoute === '/news' ? 'active' : undefined}
              id="newsLink"
            >
              News
            </Link>
            <Image
              src={'/drawings/turtle.svg'}
              alt={'Cartoon drawing of turtle'}
              width={75}
              height={50}
              className="nav-image"
            />
            <NavButton onClick={openContactModal} id="contactLink">Contact</NavButton>
          </NavLinksWrapper>
          <ToolsWrapper>
            <CartIconWrapper onClick={openCartModal}>
              <Image src={'/cart.svg'} alt={'Cart'} width={80} height={60} />
              {cartCount ? <span>({cartCount})</span> : null}
            </CartIconWrapper>
          </ToolsWrapper>
        </NavBottom>
      </Nav>
      <MenuWrapperMobile open={isOpen}>
        <div className='menu-item'>
          <Link
            href="/about"
            className={currentRoute === '/about' ? 'active' : undefined}
            id="aboutLink"
          >
            About
          </Link>
          <Image
            src={'/drawings/sb.svg'}
            alt={'Cartoon drawing of a baby lifting a barbell'}
            width={70}
            height={60}
            className="nav-image"
          />
        </div>
        <div className='menu-item'>
          <Link
            href="/products"
            className={currentRoute === '/products' ? 'active' : undefined}
            id="shopLink"
          >
            Shop
          </Link>
          <Image
            src={'/drawings/sun.svg'}
            alt={'Cartoon drawing of a man'}
            width={60}
            height={60}
            className="nav-image"
          />
        </div>
        <div className='menu-item'>
          <Link
            href="/news"
            className={currentRoute === '/news' ? 'active' : undefined}
            id="newsLink"
          >
            News
          </Link>
          <Image
            src={'/drawings/turtle.svg'}
            alt={'Cartoon drawing of turtle'}
            width={85}
            height={60}
            className="nav-image"
          />
        </div>
        <div className='menu-item'>
          <NavButton onClick={openContactModal} id="contactLink">Contact</NavButton>
          <Image
              src={'/drawings/flower.svg'}
              alt={'Cartoon drawing of turtle'}
              width={75}
              height={60}
              className="nav-image"
            />
        </div>
      </MenuWrapperMobile>
  </>
  )
}
